<template>
  <div class="accountSetting">
    <!-- <div class="mantil">账户设置</div> -->
    <!-- 大盒子 -->
    <div class="user">
      <div class="basic">
        <!-- 缩宽90% -->
        <div class="basics">
          <!-- 跳转盒子 -->
          <div class="basicsName">
            <!--b 3 绑定手机号-->
            <div class="basicsNames">
              <div class="basicsNamesTop" :class="[b == 3 ? 'basicsNamesTops' : '']" @click="tabChange(3)">
                绑定手机号
              </div>
              <div v-if="b == 3" class="basicsNamesCen" />
            </div>
            <!--b 1 修改信息-->
            <!--            <div class="basicsNames">-->
            <!--              <div-->
            <!--                class="basicsNamesTop"-->
            <!--                @click="tabChange(1)"-->
            <!--                :class="[b == 1 ? 'basicsNamesTops' : '']"-->
            <!--              >-->
            <!--                我的地址-->
            <!--              </div>-->
            <!--              <div class="basicsNamesCen" v-if="b == 1"></div>-->
            <!--            </div>-->
            <!--b 2 修改密码-->
            <div class="basicsNames">
              <div class="basicsNamesTop" :class="[b == 2 ? 'basicsNamesTops' : '']" @click="tabChange(2)">
                修改密码
              </div>
              <div v-if="b == 2" class="basicsNamesCen" />
            </div>
            <!--b 5 修改账号-->
            <!-- <div class="basicsNames">
              <div
                class="basicsNamesTop"
                @click="tabChange(5)"
                :class="[b == 5 ? 'basicsNamesTops' : '']"
              >
                修改账号
              </div>
              <div class="basicsNamesCen" v-show="b == 5"></div>
            </div> -->
            <!--b 4 关于我们-->
            <div class="basicsNames">
              <div class="basicsNamesTop" :class="[b == 4 ? 'basicsNamesTops' : '']" @click="tabChange(4)">
                关于我们
              </div>
              <div v-if="b == 4" class="basicsNamesCen" />
            </div>
          </div>
          <!-- 跳转内容 b  1 修改信息-->
          <div v-show="b == 1" class="basicsCent">
            <!-- 左侧表单 -->
            <div class="basicsCentLeft">
              <el-form ref="upDataForm" class="colleges-form" :model="ruleForm" :rules="rules" label-width="80px">
                <el-form-item label="收件人" prop="nickName ">
                  <el-input v-model="ruleForm.nickName" placeholder="请输入收件人姓名" />
                </el-form-item>
                <el-form-item label="手机号码" prop="mobile">
                  <el-input v-model="ruleForm.mobile" placeholder="请输入您的手机号码" />
                </el-form-item>

                <el-form-item label="所在地区" style="width: 470px !important">
                  <area-select v-model="vmcity" type="all" :level="2" :data="pcaa" style="
                      display: flex;
                      width: 400px;
                      height: 40px;
                      line-height: 15px;
                    " />
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                  <el-input v-model="ruleForm.address" placeholder="请填写您的具体位置，街道，楼牌号等" />
                </el-form-item>

                <div class="colleges-keep" @click="xiuGai">保存修改</div>
              </el-form>
            </div>
          </div>
          <!-- 跳转内容 b  3 绑定手机号-->
          <div v-show="b == 3" class="basicsCent">
            <!-- 左侧表单 -->
            <div class="basicsCentLeft" style="position: relative">
              <el-form ref="pform" class="colleges-form" :rules="rules" :model="pform">
                <el-form-item prop="tel" label="手机号">
                  <el-input v-model="pform.tel" placeholder="请输入手机号" />
                </el-form-item>
                <el-form-item prop="yzm" label="验证码">
                  <el-input v-model="pform.yzm" type="text" placeholder="请输入验证码">
                    <el-button v-if="!isDisabled" slot="suffix" class="inp_yzm" type="text" @click="loginSmsCode">点击获取验证码
                    </el-button>
                    <el-button v-if="isDisabled" slot="suffix" class="inp_yzm" type="text">{{ buttonName }}
                    </el-button>
                  </el-input>
                </el-form-item>
                <div class="colleges-keep" @click="pcUserBindMobile">
                  确认修改
                </div>
              </el-form>
            </div>
          </div>
          <!-- 跳转内容 b  2 修改密码-->
          <div v-show="b == 2" class="basicsCent">
            <!-- 左侧表单 -->
            <div class="basicsCentLeft" style="position: relative">
              <el-form ref="passwordForm" class="colleges-form" :rules="rules" :model="form">
                <el-form-item label="旧密码" prop="password">
                  <el-input v-model="form.password" placeholder="请输入您现在的登录密码" show-password />
                </el-form-item>
                <el-form-item label="新密码" prop="passwords">
                  <el-input v-model="form.passwords" placeholder="请输入您的新密码" show-password />
                </el-form-item>
                <el-form-item label="确认密码" prop="passwordss">
                  <el-input v-model="form.passwordss" placeholder="请确认您的新密码" show-password />
                </el-form-item>

                <div class="colleges-keep" @click="xiuGaimima">确认修改</div>
              </el-form>
            </div>
          </div>
          <!-- 跳转内容 b  5 修改账号-->
          <div v-show="b == 5" class="basicsCent">
            <!-- 左侧表单 -->
            <div class="basicsCentLeft" style="position: relative">
              <el-form ref="accountForm" class="colleges-form" :rules="rules" :model="accountFrom">
                <el-form-item label="当前账号:" prop="account">
                  <el-input v-model="accountFrom.account" disabled />
                </el-form-item>
                <el-form-item label="新账号:" prop="accountNew">
                  <el-input v-model="accountFrom.accountNew" placeholder="请输入新账号" @input="accountInput" />
                </el-form-item>

                <div class="colleges-keep" @click="editAccount">确认修改</div>
              </el-form>
            </div>
          </div>
          <!-- 跳转内容 b  4 关于我们-->
          <div v-show="b == 4" class="basicsCent">
            <div class="aboutus" v-html="ucon" />
          </div>
        </div>
      </div>
    </div>
    <!-- 修改手机号绑定 -->
    <el-dialog title="修改绑定" :visible.sync="dialogVisible" width="30%">
      <phone-change v-if="dialogVisible" @isOk="dialogVisible = false" />
    </el-dialog>
  </div>
</template>
<script>
import { imagecode, selectCode } from '@/api/register'
import { pca, pcaa } from 'area-data' // 城市数据
import {
  getPcUser,
  updatePcUser,
  updatePcUserPassword,
  updateMUserPassword,
  updateStudentUserAccount
} from '@/api/user'
import PhoneChange from '@/components/mine/phoneChange.vue'
import { CouponsClient } from '@/api/mine/couponsClient'
import { setInfo, setToken, getInfo } from '@/api/cookies'
import { getDnsOneApi } from '@/api/home'
import { Form as ElForm, Input } from 'element-ui'
const CouponsClients = new CouponsClient()
export default {
  components: { PhoneChange },

  data() {
    const validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请确认您的新密码'))
      } else if (value !== this.form.passwords) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      pform: {
        tel: '',
        yzm: ''
      },
      isDisabled: false,
      timeshow: 60,
      buttonName: '',
      ucon: '',
      userInfo: null,
      vmcity: [], // 地址
      pca: pca,
      pcaa: pcaa,
      name: '',
      names: '',

      imageUrl: '',
      user: '',
      tabIndex: 1,
      // 全部订单
      b: 3,
      ifclick: true,
      captcha: '',
      ruleForm: {},
      detail: {
        img: '',
        nickName: ''
      },

      form: {
        password: '',
        passwords: '',
        passwordss: '',
        imgCode: ''
      },

      rules: {
        nickName: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur'
          }
        ],
        tel: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur'
          }
        ],
        gender: [
          { required: true, message: '性别不能为空', trigger: 'change' }
        ],
        email: [
          { required: true, message: '电子邮箱不能为空', trigger: 'blur' }
        ],
        password: [
          {
            required: true,
            message: '请输入您现在的登录密码',
            trigger: 'blur'
          },
          { min: 6, message: '密码长度不得小于6位数', trigger: 'blur' }
        ],
        passwords: [
          { required: true, message: '请输入您的新密码', trigger: 'blur' },
          { min: 6, message: '密码长度不得小于6位数', trigger: 'blur' }
        ],
        passwordss: [
          { required: true, validator: validatePass2, trigger: 'blur' },
          { min: 6, message: '密码长度不得小于6位数', trigger: 'blur' }
        ],
        imgCode: [
          { required: true, message: '请输入右边验证码', trigger: 'blur' }
        ],
        yzm: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        accountNew: { required: true, message: '请输入账号', trigger: 'blur' }
      },
      type: {
        sjh: 'sjh',
        qq: 'qq',
        wx: 'wx'
      },
      title: '修改绑定',
      titles: '已绑定',
      /* 修改绑定手机号 */
      dialogVisible: false,
      fileList: [
        {
          name: '默认头像.jpeg',
          url: 'https://staticfile.xlcwx.com/avatar/icon1.png'
        }
      ],
      imgVal: 99,
      imgList: [
        'https://staticfile.xlcwx.com/avatar/icon0.png',
        'https://staticfile.xlcwx.com/avatar/icon1.png',
        'https://staticfile.xlcwx.com/avatar/icon2.png',
        'https://staticfile.xlcwx.com/avatar/icon3.png',
        'https://staticfile.xlcwx.com/avatar/icon4.png',
        'https://staticfile.xlcwx.com/avatar/icon5.png',
        'https://staticfile.xlcwx.com/avatar/icon6.png',
        'https://staticfile.xlcwx.com/avatar/icon7.png'
      ],
      /* 修改账号 */
      accountFrom: {
        account: getInfo().account
      }
    }
  },
  async created() {
    this.b = this.$route.query.type ? Number(this.$route.query.type) : 3
    this.userInfo = await getInfo()
    this.loadgetPcUser()
    this.loadCaptcha()
    if (!this.userInfo) {
      return
    }
    if (this.userInfo.mobile) {
      this.title = '修改绑定'
      this.titles = '已绑定'
    } else {
      this.title = '立即绑定'
      this.titles = '未绑定'
    }
    await getDnsOneApi().then((res) => {
      if (res.code == 200) {
        this.ucon = res.msg.entrants.about
      }
    })

    // 调用验证码接口方法

    // 调用基本资料接口方法
  },
  methods: {
    async loginSmsCode() {
      if (!this.pform.tel) {
        this.$message.error('请输入手机号')
      }
      if (!/^[1]([3-9])[0-9]{9}$/.test(this.pform.tel)) {
        this.$message.error('手机号格式不正确')
        return
      }
      await CouponsClients.loginSmsCode(this.pform.tel, 'confirmToken').then(
        (res) => {
          if (res.code == 0) {
            this.$message({
              message: '发送成功',
              type: 'success'
            })
            const me = this
            me.isDisabled = true
            const interval = setInterval(() => {
              me.buttonName = `${me.timeshow}秒后重新发送`
              if (me.timeshow < 0) {
                me.buttonName = '重新发送'
                me.timeshow = 60
                me.isDisabled = false
                window.clearInterval(interval)
              } else {
                me.timeshow--
              }
            }, 1000)
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    async pcUserBindMobile() {
      this.$refs.pform.validate(async (valid) => {
        if (valid) {
          await CouponsClients.pcUserBindMobile(
            this.pform.tel,
            this.pform.yzm
          ).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },

    // 固定图片选择
    imgClack(wx) {
      this.imgVal = wx
    },
    // 调用验证码接口方法
    loadCaptcha() {
      imagecode().then((res) => {
        this.captcha = `data: image/jpeg;base64,${btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )}`
      })
    },
    // 调用基本资料接口方法
    loadgetPcUser() {
      getPcUser(this.userInfo.id).then((res) => {
        localStorage.setItem('userId', res.msg.id)
        setInfo(res.msg)
        this.ruleForm = res.msg
        this.vmcity = res.msg.provinceAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.provinceAddress)))
          : this.vmcity.concat([])
        this.vmcity = res.msg.cityAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.cityAddress)))
          : this.vmcity.concat([])
        this.vmcity = res.msg.areaAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.areaAddress)))
          : this.vmcity.concat([])
        this.$forceUpdate()

        if (this.ruleForm.img != this.userInfo.img) {
          // 页面强制刷新
          window.location.reload()
        }
      })
      this.$forceUpdate()
    },
    // 基本资料 修改信息
    async xiuGai() {
      this.$refs.upDataForm.validate(async (valid) => {
        if (valid) {
          if (this.imgVal != 99) {
            this.ruleForm.img = this.imgList[this.imgVal]
          }
          updatePcUser(
            this.ruleForm.id,
            this.ruleForm.userName,
            this.ruleForm.nickName,
            this.ruleForm.mobile,
            this.ruleForm.sex,
            this.ruleForm.address,
            this.vmcity[0],
            this.ruleForm.img,
            this.vmcity[1],
            this.vmcity[2],
            this.ruleForm.email
          ).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg)
              getPcUser(this.userInfo.id).then((res) => {
                localStorage.setItem('userId', res.msg.id)
                setInfo(res.msg)
              })

              // 页面强制刷新
              setTimeout(function () {
                // window.location.reload();
              }, 2000)
            }
          })
        }
      })
    },
    // 基本资料 修改密码
    xiuGaimima() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          if (this.form.passwords == this.form.passwordss) {
            updateMUserPassword(
              this.userInfo.id,
              this.form.password,
              this.form.passwords
            ).then((res) => {
              if (res.code == 0) {
                this.form.password = ''
                this.form.passwords = ''
                this.form.passwordss = ''
                this.$message.success(res.msg)
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.$message.error('请输入相同的新密码')
          }
        }
      })
    },
    // 基本资料 修改账号
    editAccount() {
      this.$refs.accountForm.validate((valid) => {
        if (!valid) return
        const rule = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{2,12}$/
        if (!rule.test(this.accountFrom.accountNew)) {
          this.$message({
            type: 'error',
            message: '请输入2~12位数字字母组合'
          })
          return
        }
        const data = {
          account: this.accountFrom.accountNew
        }
        updateStudentUserAccount(data).then((res) => {
          if (res.code == 0) {
            this.userInfo.account = this.accountFrom.accountNew
            this.accountFrom.accountNew = ''
            setInfo(this.userInfo)
            this.userInfo = getInfo()
            this.accountFrom.account = this.userInfo.account
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    accountInput(value) {
      if (!value || value == ' ') {
        this.accountFrom.accountNew = ''
      }
      const rule = /[\W]/g
      this.$nextTick(() => {
        this.accountFrom.accountNew = value.replace(rule, '')
      })
    },
    /* 标签页修改 */
    tabChange(val) {
      try {
        if (this.$refs.upDataForm) {
          this.$refs.upDataForm.resetFields()
        }

        if (this.$refs.pform) {
          this.$refs.pform.resetFields()
        }

        if (this.$refs.passwordForm) {
          this.$refs.passwordForm.resetFields()
        }
        this.b = val
        this.$forceUpdate()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.accountSetting {
  padding: 0px 24px;
}

.colleges-form {
  margin: 0 auto;

  /deep/ .el-input {
    width: 366px !important;
    height: 42px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px solid #dde0e7;
  }
}

.colleges-keep {
  width: 480px;
  height: 50px;
  background: linear-gradient(90deg, #0270e8 0%, #21b4f5 100%);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  font-size: 16px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  margin-left: 0;
  text-align: center;
}

.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}

.el-input.el-input__inner {
  width: 100px;
}

.choose {
  border: 2px solid rgba(31, 150, 241, 1);
}

.chooseNot {
  border: 2px solid #fff;
}

.tips {
  width: 100%;
  height: auto;
  color: rgba(123, 129, 150, 1);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.file-upload {
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  color: #ff4027;
  text-align: center;
  line-height: 26px;
  margin: 10px 0 auto auto;
}

.file-upload-input {
  background-color: transparent;
  width: auto;
  height: 50px;
  cursor: pointer;
}

.basic {
  width: 100%;
  min-height: 408px;
  background: #ffffff;

  // 缩宽
  .basics {
    width: 100%;
    margin: auto;
    padding-top: 20px;

    // 标题
    p {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
    }

    // 跳转盒子
    // 跳转盒子
    .basicsName {
      width: 100%;
      height: 38px;
      display: flex;
      align-items: center;
      // padding: 0px 32px;
      border-bottom: 1px solid #eee;

      .basicsNames {
        /*   width: 65px; */
        height: 100%;
        margin-right: 41px;
        cursor: pointer;

        .basicsNamesTop {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }

        .basicsNamesTops {
          color: #333333;
        }

        .basicsNamesCen {
          width: 24px;
          height: 2px;
          margin: 14px auto 0px;

          background: linear-gradient(90deg, #0270E8 0%, #21B4F5 100%);
          border-radius: 1px;
        }
      }
    }

    // 跳转内容 b = 1 修改信息 b = 2 修改信息密码
    .basicsCent {
      width: 480px;
      height: auto;
      margin: 0 auto;
      border-top: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 80px;

      // 左侧
      .basicsCentLeft {
        height: 100%;
        margin-top: 50px;

        /deep/ .el-form {
          width: 480px;

          .el-form-item__label {
            width: 83px;
            text-align: left;
            margin-right: 0;
          }

          .el-form-item {
            margin-bottom: 30px;
          }

          .inp_yzm {
            width: 112px;
            margin-top: 5px;
            height: 32px;
            background: linear-gradient(90deg, #0270e8 0%, #21b4f5 100%);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            line-height: 0px;
            font-size: 14px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }

      .aboutus {
        width: 100%;
        margin: 0 auto;
        margin-top: 80px;
      }
    }
  }
}

.colleges-keep {
  margin: 22px auto 0px;
}

.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

/deep/ .area-select {
  height: 40px;
  line-height: 22px;
}

/deep/ .area-select {
  margin-left: 0 !important;
  margin-right: 10px;

  .area-select-icon {
    // width: 10px;
    // height: 5px;
    border-top-color: #cccccc;
    // background: #cccccc;
    // opacity: 1;
  }
}

/deep/ .area-select-wrap {
  margin-left: 0;
}

/deep/ select::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #666666;
}

/deep/ .el-input__inner {
  background: #FFFFFF !important;
}
</style>
